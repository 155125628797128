import React from 'react'
import ReactDOM from 'react-dom'

import LoginForm from '../LoginForm'

import 'antd/dist/antd.css'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <LoginForm />,
    document.body.appendChild(document.createElement('div')),
  )
})
