import React, { useState } from 'react'
import { Form, Icon, Input, Button } from 'antd'

function LoginForm(props) {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const submit = (e) => {
    e.preventDefault()
    fetch('/users/sign_in', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user: {email, password}})
    }).then(() => {
      window.location.reload();
    })
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
  };

  return (
    <Form
      {...layout}
      onSubmit={submit}
      name="login"
    >
      <Form.Item
        label="Utilisateur"
        name="username"
        rules={[{ required: true, message: 'Veuillez renseigner votre nom d\'utilisateur' }]}
      >
        <Input
          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Utilisateur"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Mot de passe"
        name="password"
        rules={[{ required: true, message: 'Veuillez renseigner votre mot de passe' }]}
      >
        <Input.Password
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Mot de passe"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" className="login-form-button" onClick={(e) => submit(e)}>
          Connexion
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm